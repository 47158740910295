import { init } from '@adobe/exc-app'

import { getIsInsideExperienceCloud } from './helpers'

export default () =>
  new Promise((resolve, reject) => {
    if (getIsInsideExperienceCloud() || 'exc-module-runtime' in window) {
      init(resolve)
    } else {
      reject(
        new Error(
          'Module Runtime unavailable. Please goto https://experience-qa.adobe.com/maestro'
        )
      )
    }
  })
