import page from '@adobe/exc-app/page'
import { LifeCycles, registerApplication, start } from 'single-spa'
import { injectGlobal } from 'workfront-twind'

import excAppBootstrapper from './excapp-bootstrapper'
import { getURLParamsProps } from './helpers'
import inspectorBootstrapper from './inspector-bootstrapper'
import routes from './routes'

injectGlobal`
  #root, #page-content {
    height: 100%;
  }
  html, body, .random-class {
    height: 100%;
    padding: 0;
    margin: 0;
  }
`

registerApplication({
  name: '@wf-mfe-maestro/layout',
  app: () => System.import<LifeCycles>('@wf-mfe-maestro/layout'),
  activeWhen: () => true,
})

Promise.all([excAppBootstrapper(), inspectorBootstrapper()]).then(() => {
  start()
})

routes.forEach((route) => {
  registerApplication({
    name: route.application,
    app: () => System.import<LifeCycles>(route.application),
    activeWhen: (location) => !!getURLParamsProps(location, route.paths),
    customProps: (_name, location) => getURLParamsProps(location, route.paths),
  })
})

window.addEventListener(
  'single-spa:first-mount',
  () => {
    page.done()
  },
  {
    once: true,
  }
)
