import { match as urlMatch } from 'path-to-regexp'

export const getParentOrigin = () => {
  const locationsAreDistinct = window.location !== window.parent.location
  const parentOrigin = (
    (locationsAreDistinct ? document.referrer : document.location) || ''
  ).toString()

  if (parentOrigin) {
    return new URL(parentOrigin).origin
  }

  const currentLocation = document.location

  // this is not supported in Firefox, so the checks above are required
  if (
    currentLocation.ancestorOrigins &&
    currentLocation.ancestorOrigins.length
  ) {
    return currentLocation.ancestorOrigins[0]
  }

  return ''
}

/**
 * Checks if running app is running ExC by checking the parent origin and if that fails then falling back to session storage (which checks if the module runtime script is present)
 * @returns boolean
 */
export const getIsInsideExperienceCloud = () => {
  const parentOrigin = getParentOrigin()
  const isExCParentOrigin =
    /(https:\/\/)(exc-unifiedcontent\.)?experience(-qa|-stage)?\.adobe\.(com|net)$/.test(
      parentOrigin
    )
  const checkIfPreviouslyLoadedModuleRuntime =
    window.sessionStorage.getItem('unifiedShellMRScript') !== null
  return isExCParentOrigin || checkIfPreviouslyLoadedModuleRuntime
}

// TODO: If we're going to rely on react-router-dom, then we don't need this
export const getURLParamsProps = (location: Location, paths: string[]) => {
  const locationPath = location.pathname

  for (const path of paths) {
    const matchURLParamsFn = urlMatch(path)
    const match = matchURLParamsFn(locationPath)
    if (match) {
      return { routerParams: match.params }
    }
  }
  return false
}
