export default () =>
  Promise.resolve().then(() => {
    if (window.localStorage.getItem('devtools')) {
      return System.import('@wf-mfe/workfront-inspector')
        .then((workfrontInspector) =>
          (workfrontInspector.default || workfrontInspector)()
        )
        .catch((err) => {
          console.error('Error loading Workfront Inspector', err)
        })
    }
  })
