export default [
  {
    application: '@wf-mfe-maestro/grid',
    paths: ['/:workspaceID/:recordTypeID/table'],
  },
  {
    application: '@wf-mfe-maestro/calendar',
    paths: ['/:workspaceID/:recordTypeID/timeline'],
  },
  {
    application: '@wf-mfe-maestro/details-page-ui',
    paths: ['/:workspaceID/:recordTypeID/:recordID/record'],
  },
  {
    application: '@wf-mfe-maestro/workspaces',
    paths: ['/workspaces/:workspaceId?'],
  },
]
